<template>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Agregar correo electrónico de recordatorios
        <template v-slot:actions>
          <v-icon color="primary" @click.native.stop="reloadp++">
            mdi-refresh
          </v-icon>
          <v-icon
            color="error"
            @click="$store.dispatch('deleteWindowByUuid', {uuid: win.uuid})"
          >
            mdi-close
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
  
            <v-tab href="#tab-1">
                Agregar correo electrónico de recordatorios
              <v-icon>mdi-plus</v-icon>
            </v-tab>
          </v-tabs>
  
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card class="white" flat>
                <v-card-text class="email-wrapper">
                  <v-card-text class="form-height">
                    <ManageRemindersEmailSchedule :key="reloadp"  ></ManageRemindersEmailSchedule>
                  </v-card-text>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </template>
    
  <script>
  import ManageRemindersEmailSchedule from "./manageRemindersEmailSchedule.vue";
  
  export default {
    name: "AddRemindersEmailSchedule",
    components: {
        ManageRemindersEmailSchedule,
    },
    props: ["win"],
    data: () => ({
      tab: null,
      reloadp: 0,
    }),
    methods: {
    },
  };
  </script>
  
  <style scoped>
  .form-height{
        min-height: 200px;
  }
  </style>